import React, { useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import queryString from 'query-string';
import Typography from '@material-ui/core/Typography';
import localStorage from 'store2';

import { useSyncUser } from '~hooks/useAuth';
import { useUserContext } from '~context/UserContext/UserSessionContext';

import { setAuthHeader } from '~utils/request';

import Container from '~components/Container';
import LoadingSpinner from '~components/LoadingSpinner';
import BackgroundImage from '~images/other/flow-background.svg';
import useConversionFramework from '~hooks/useConversionFramework/useConversionFramework';

const LOCAL_STORAGE_KEY = 'apple-sign-in-url';

const AppleSignInContainer = memo(({ location }) => {
  const { isMarketingSite } = useConversionFramework();
  const { session } = useUserContext();
  const redirectUrl = localStorage.get(LOCAL_STORAGE_KEY);

  const { search } = location; // query string from the url
  const parsedSearchQuery = search && queryString.parse(search);
  const { token, error } = search && parsedSearchQuery;

  const { isLoggedIn } = session;

  if (token) {
    setAuthHeader(token, isMarketingSite);
  }

  useSyncUser();

  useEffect(() => {
    if (token && isLoggedIn) {
      navigate(`/checkout/${redirectUrl}`);
      return;
    }
    if (error) {
      navigate(`/checkout/${redirectUrl}`, {
        state: {
          appleError: error
        }
      });
      return;
    }
    navigate('/checkout/login');
  }, [error, isLoggedIn, redirectUrl, token]);

  return (
    <Container center size={100} background={`url(${BackgroundImage})`} maxWidth="md">
      <>
        <Typography variant="h3" align="center">
          Please wait while we are signing you in.
        </Typography>
        <LoadingSpinner />
      </>
    </Container>
  );
});

AppleSignInContainer.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string
  }).isRequired
};

export default AppleSignInContainer;
