import React from 'react';
import PropTypes from 'prop-types';

import Page from '~layout/Page';
import AppleSignInContainer from '~modules/apple-sign-in';

const AppleSignInPage = ({ location }) => (
  <Page>
    <AppleSignInContainer location={location} />
  </Page>
);
AppleSignInPage.propTypes = {
  location: PropTypes.shape({}).isRequired
};

export default AppleSignInPage;
